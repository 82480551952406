/*
 * @Author: a3802 253092329@qq.com
 * @Date: 2023-05-25 00:33:21
 * @LastEditors: a3802 253092329@qq.com
 * @LastEditTime: 2024-08-26 17:53:09
 * @FilePath: \tgvue\src\api\index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import axios from './request'


/**获取次数 */
export const getNum = (data) => {
    return axios({
        url: '/duanju/getNum',
        method: 'post',
        data
    })
}



/**修改次数 */
export const countNum = (data) => {
    return axios({
        url: '/duanju/countNum',
        method: 'post',
        data
    })
}


/**退款 */
export const refund = (data) => {
    return axios({
        url: '/duanju/refundOrder',
        method: 'post',
        data
    })
}

/**发送验证码 */
export const sendsms = (data) => {
    return axios({
        url: '/duanju/sendsms',
        method: 'post',
        data
    })
}

/**领取权益 */
export const getEquity = (data) => {
    return axios({
        url: '/duanju/equitygift',
        method: 'post',
        data
    })
}



/**继续支付 */
export const btnPay = (data) => {
    return axios({
        url: '/duanju/btnPay',
        method: 'post',
        data
    })
}






export default { getNum, refund, countNum, sendsms, getEquity, btnPay }